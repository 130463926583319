// Search form
.search-form {
	@extend .form-inline;
}
.search-form label {
	font-weight: normal;
	@extend .form-group;
}
.search-form .search-field {
	@extend .form-control;
	border-radius: 0;
}
.search-form .search-submit {
	@extend .btn;
	@extend .btn-secondary;
}
#stgType {
	height: 51px;
}

/* to keep submit on same line as input on mobile */
.search-form label {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 0;
}


/* GRAVITY FORMS */
.gform_wrapper.gravity-theme .gfield input, .gform_wrapper.gravity-theme .gfield select {
    border-radius: 10px;border: 1px solid #666
}
.gform_wrapper.gravity-theme .gfield textarea { border-radius: 10px; border: 1px solid #666; }
.gform_required_legend { display:none; }	
@media screen and (min-width: 641px) {  
	.gform_wrapper textarea.large { height: 160px !important; width: 100%; }
	body .gform_wrapper ul.gfield_radio li { display: inline; padding-right: 10px!important; }  /* to display radio fields inline */
}
body .gform_wrapper ul li.field_description_below div.ginput_container_radio,
body .gform_wrapper ul li.field_description_below div.ginput_container_checkbox,
body .gform_wrapper.gf_browser_chrome .gfield_checkbox li input, 
body .gform_wrapper.gf_browser_chrome .gfield_checkbox li input[type=checkbox], 
body .gform_wrapper.gf_browser_chrome .gfield_radio li input[type=radio] { margin-top: 0; }

.gform_wrapper .gform_validation_errors>h2 { font-size: 1rem !important;}
h3.gsection_title { margin-top:2rem; }

.gform_wrapper.gravity-theme .ginput_complex .ginput_full {  flex: 0 0 50%; }	/* street address */
.ginput_container_radio .gfield_radio > div { display:inline; margin-right:15px;  }  /* Radio */

.userCompanyEmail { display:none;}


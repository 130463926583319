.btn {
	border-radius: 30px;
	transition: all 0.2s ease-out 0s;
	text-transform: uppercase;
	line-height: 1.5rem;
	font-size: 14px; font-weight:600;
	padding: 0.25rem 1.5rem;
	overflow: hidden;
	position: relative;
	cursor: pointer;
	white-space: normal;
	
	&.brand {
		background: $brand-primary;
		color: $brand-primary-text;
		&:hover {
			background: linear-gradient(lighten($brand-primary, 12%), lighten($brand-primary, 8%), lighten($brand-primary, 3%));
			box-shadow: $dp-2;
		}
		&:active  {
			background-color: darken( $brand-primary, 10% );
		}
	}
	&.brand-secondary {
		background: $brand-secondary;
		color: $brand-secondary-text !important;

		&:hover {
			background: linear-gradient(lighten($brand-secondary, 12%), lighten($brand-secondary, 8%), lighten($brand-secondary, 3%));
			box-shadow: $dp-2;
		}
		&:active  {
			background-color: darken( $brand-secondary, 10% );
		}
	}
	&.brand-gold {
		background: $gold;
		color: #fff !important;

		&:hover {
			background: linear-gradient(lighten($gold, 12%), lighten($gold, 8%), lighten($gold, 3%));
			box-shadow: $dp-2;
		}
		&:active  {
			background-color: darken( $gold, 10% );
		}
	}	
	
}

/* match Gravity forms to button above */
body .gform_wrapper .gform_footer input.button, 
body .gform_wrapper .gform_footer input[type=submit] {
    padding: 0.25rem 1.5rem;
    font-size: 1rem;	
	border-radius: 30px;
	transition: all 0.2s ease-out 0s;
	text-transform: uppercase;
	line-height: 1.5rem;
	font-weight: 600;
	overflow: hidden;
	position: relative;
	cursor: pointer;
	background: $brand-primary;
	color: $brand-primary-text !important;
	border:0; 
	&:hover {
		background: linear-gradient(lighten($brand-primary, 12%), lighten($brand-primary, 8%), lighten($brand-primary, 3%));
		box-shadow: $dp-2;
	}
}


@keyframes ripple {
	0% {
		transform: scale(0);
	}
	20% {
		transform: scale(1);
	}
	100% {
		opacity: 0;
		transform: scale(1);
	}
}
.btn:not(:active):after {
  animation: ripple 1s ease-out;
}
.btn:after {
	visibility: hidden;
}
.btn:focus:after {
	visibility: visible;
}
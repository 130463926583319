#prefooter { 
	margin:2.5rem 0; 
	div.logos a { 
		text-align:center; display:inline-block; margin: 5px 12px;   
	}	
}

footer.site-footer {
	background: $brand-primary;
	color: #ccc;
	a { color:#fff; }
	padding: 3rem 0;
	font-size: 14px; 
	h3 {
    	color: $red;
    	font-weight: 400;
    	font-size: 1.25rem!important;
	}	
	ul.menu {
		list-style-type: none;
		padding: 0; margin-bottom: 0;
		li { padding-bottom:.5rem;}
		a { 
			font-size: 14px;
			font-weight: 400; 
			text-decoration: underline;
			color:#ccc; 
		}
		a:hover { text-decoration: none; }
	}	

	div.brand img { margin: 0 auto 1rem; }	
	
	.copyright { padding-bottom: 1rem; font-size:14px;  }
	.copyright a { text-decoration:underline; }
	
	.social img { padding-bottom: 10px; float: left; margin-right: 0.5rem; }	
}
	
.home {
	#hero { 
		padding-bottom: 1em; 
		background-color: $light-gray; 
		min-height: 500px; 
		padding-top: 100px; 
		margin: 0;
		div.block { padding: 40px 30px; background:rgba(255,255,255,.75); border-radius:10px;  }	
		h1 { font-size:70px; font-weight:600; }
		a:hover { text-decoration: none; }
	}
	
	#services {
		text-align:center;
		div.services-border { border:1px solid $red; border-radius:10px; padding: 20px;  }
		
		h2.title { 
			background:#fff; font-size:36px !important; font-weight:600;
			position:relative; color:$dark-gray;
    		display:inline;
    		padding:0 20px;
    		z-index:50; top:-40px; 
		} 
		
		.block { border-radius:10px; min-height:210px;   } 
		.block-title { 
			position:absolute; bottom:0; 
			background:rgba(0,0,0,.5); 
			color:#fff; 
			text-align:center; 
			padding:10px; 
			width:calc(100% - 30px); 
			a { color:#fff; text-decoration:none; }
			img { display:inline; vertical-align:top; margin-left:5px }	
		}	
		h3 {
			text-transform: uppercase;
			text-align: center;
			font-size: 1.25rem !important;
			font-weight: 400;
			margin-bottom: 0;
			color:#fff;
			display:inline;
		}

	} 

	#value { 
		background: $dark-gray; 
		color: #fff; 
		padding: 4rem 0; 
		margin: 0; 
		font-weight: 200; 
		text-align: center; 	
		h2 { font-size: 50px !important; font-weight: 200; color: #fff; } 
		.quote-left, .quote-right { display: inline-block; width: 15%; min-height: 80px; vertical-align: top;   }	
		.quote-left { background:url('/wp-content/uploads/2022/08/quote-left.png') no-repeat; background-size:contain; background-position: 0 20px }
		.quote-right { background:url('/wp-content/uploads/2022/08/quote-right.png') no-repeat; background-size:contain; background-position: 0 20px }	
		.quote { padding:10px; font-size:24px; line-height:1.15; display:inline-block; width: calc(70% - 40px); min-height:80px; vertical-align:top; 
			span { display:block; padding-top:10px; font-size:18px; font-weight:600; }	
		}
	}
	
	#highlights { 
		background: #E1E1E1; 
		padding: 4rem 0; 
		margin: 0; 
		h2 { color:$red; font-size: 22px !important; } 
		.large { font-size:50px; line-height:1.1; font-weight:200; padding-top:10px; }
	}
	
	#testimonials {
		h2 {
			margin-bottom: 3rem;
			position: relative;
		}
		h2:after {
			content:'';
			width: 2.5rem;
			height: 2px;
			background: $brand-secondary;
			position: absolute;
			left: 48%; /* temp */
			bottom: -0.75rem;
		}
		.blockquote, .blockquote-footer {
			font-size: 1rem;
			padding: 0 1rem;
		}
		.slick-next::before, .slick-prev::before {
			color: $brand-secondary;
			font-size: 2rem;
		}
		.slick-prev { left: -4rem; }
		.slick-next { right: -4rem; }
	}
	
}

div.horiz-rule {
    position: absolute;
    top: 0;
    height: 20px;
    border-bottom: 1px solid #000;
    width: calc(100% - 30px);
    z-index: 5;
}
h2.horiz-rule {
    position: relative;
    display: inline;
    padding: 0 20px;
    z-index: 50;
}


#featured-panel { background:#E1E1E1; padding:3rem 0 4rem; margin:0; }	
#featured-panel h2 { font-weight: 200; font-size: 2rem!important; }		
#featured-panel h2.horiz-rule { background:#E1E1E1; } 

#more-panel { background:#636466; padding:3rem 0 4rem; margin:0; }	
#more-panel h2 { color:#fff; font-weight: 200; font-size: 2rem!important; }		
#more-panel h2.horiz-rule { background:#636466;  }	
#more-panel div.horiz-rule { border-bottom: 1px solid #fff;  }



/*  #featured-testimonial { background: #cd0d40; margin:0; padding:3rem 0; color:#fff; line-height:1.25; }
#featured-testimonial .container { background: url('/wp-content/uploads/2022/06/quotes.png') no-repeat; max-width:960px; min-height:120px;   }
#featured-testimonial .review { font-style:italic; padding:.5rem 0 0 200px; font-size:26px; font-weight:300;  }
#featured-testimonial .author { font-weight:600; padding-left: 200px; }
@media screen and (max-width:576px) {
	#featured-testimonial .review { padding:.5rem 0 0 0; font-size:20px; font-weight:300;  }
	#featured-testimonial .author { padding-left: 50px; text-align:right; }
}  */


/* CAREERS PAGE */
.career-post-sidebar { 
	.btn { width:100%; }
}
.career-post-footer { 
	@include media-breakpoint-down(md) {
		display:none;
	}
}


/* Roll up text */
.rollover-roll-text {
	.caption {
		color: $white;
		position: absolute;
		width: 100%;
		height: 0;
		overflow: hidden;
		bottom: 0;
		left: 0;
		right: 0;
		text-align:center;
		background: rgba(0, 0, 0, 0.5);
		transition: height 0.3s;

		.caption-inner {
			position: absolute;
			top: 40%;
			left: 50%;
			transform: translateX(-50%) translateY(-50%);
	    }
	}
	.caption-title {
		background: #cd2027;
		position:absolute; 
		bottom:0; 
		width:100%;
    }	
	&:hover {
    	.caption { height: 100%; }
  	}
}


/* image gallery */
.product-image-gallery {
	padding: 0;
 	li {
		list-style-type: none;
		float: left;
		display: none;
		padding: 3px;

		&:first-child {
			display: block;
		}
		img {
			width: 100%;
			height: 100%;
			max-height: 100%;
		}
		.card-block {
			p {
				display: block;
				text-overflow: ellipsis;
				word-wrap: break-word;
				overflow: hidden;
				max-height: 3em;
				line-height: 1.5em;
				margin-bottom: 0;
			}
		}
	}
}
.product-image-gallery-nav {
	padding: 0;
	li {
		list-style-type: none;
		float: left;
		/*max-width: 147px;  1920 width mainly the max for this */
		display: none;
		margin: 0 0.25em;
		transition: border-bottom 0.1s;
		border-bottom: 0.25em solid $white;
		img {
			padding: 0.25em 0;
			&:hover {
				cursor: pointer;
			}
		}
	}
	li.slick-current {
		border-bottom: 0.25em solid $brand-secondary;
	}
}



/*  media queries  */
@media (max-width: 1200px) {}
@media (max-width: 992px) {}
@media (max-width: 768px) {}
@media (max-width: 544px) {}

// Webkit
@-webkit-keyframes wobble  {
  0%  { -webkit-transform:  rotate(0deg); }
  20%  { -webkit-transform:  rotate(2deg); }
  50%  { -webkit-transform:  rotate(-2deg); }
  100%  { -webkit-transform:  rotate(0deg); }
}
header.banner {
	#topbar { background: #000; color:#fff;
		div.tagline { float:left; text-transform:uppercase; padding-top: 4px;}
	}
	padding: 0 0 0.5rem; 
	z-index: 9999;
	background: $red;
	.logo {	}
	
	#menu-header-menu {
		@include media-breakpoint-down(md) { display:none; }
		li {
			padding: 0 .5em;
			&:last-child {
				padding-right: 0;
			}
			&:after {
				content: "|";
    			color: $brand-secondary;
			}
			a {
				color: #fff;
				font-size: 0.9rem;
				text-transform: uppercase;
				line-height: 30px;  /* to align header nave and phone */
				padding-right: 1rem;
			}
		}
	}

	.search { 
		margin: 0 20px 0 15px;
		float:right; 
	}
	.search-item { content: url('/wp-content/uploads/2022/06/magnifier-glass-wht.png'); margin-top:5px; height:20px; cursor:pointer; }	
	.search-toggle { display:none; position:absolute; top: 30px; z-index: 100; right: 0; }
	.search-box { padding:20px; background:white; box-shadow: 0 3px 10px rgba(0, 0, 0, 0.3); }		
	
	.phone {
		text-align:right; width:100%; padding-top:25px;
		@include media-breakpoint-down(md) { padding-right: 55px; padding-top:15px; }
		a { 
			font-size: 1.25rem;
			color: #fff;
			@include media-breakpoint-down(sm) { font-size: 1rem; font-weight:600; }
		}
	}
	
	a.btn { float: right; margin-top:5px;  }	
	
	.main-nav-container { margin-top: 8px; }


	.nav-primary {
		ul.nav > li > a { text-transform:uppercase; font-size:18px; padding: 0.5rem 0 0.5rem 1rem; } /* top level only */
		
		ul.nav > li > a:after { 
			padding-left: 1rem; color: #000; font-weight:400; 
			@include media-breakpoint-up(lg) {  content: "|"; }
		}	
		ul.nav > li:last-child > a:after { content:""; }

		transition: margin-top 0.1s;
		li {
			transition: border-color 0.2s ease;
			padding: 0;
			& > ul {
				display: none;
			}
			a {
				padding: .5rem 1.25rem;
				display: block;
				color: #fff; 
				text-decoration: none;
				font-weight: 600;
			}
			&.last a { padding-right: 0; }				
			&:hover {
				& > ul {
					display: block;
					position: absolute;
				}
			}
			&.mobile { display:none; }	
			.sub-menu {
				margin: 0;
				padding: 15px 0;
				z-index: 9999;
				font-size: .9rem;
				background-color: #e3e3e3;
				li {
					list-style-type: none;
					position: relative;
					padding: 0 20px; 
					.sub-menu {
						width: 100%;
						position: absolute;
						left: 100%;
						top: 0;
					}
					a:hover { 
						background-color: rgba(0,0,0,.1); 
					}
				}
			}
		}
	}
}


/* Mega Menu - NOT USEING */
@media screen and (min-width: 992.1px) {
	header.banner .nav-primary li .sub-menu { background-color: #000; }
	header.banner .nav-primary li .sub-menu a { color: #fff; }	
	header.banner .nav-primary li.mega > .sub-menu { padding-bottom: 20px;   }	
	header.banner .nav-primary li.mega .sub-menu ul.sub-menu  { padding: 0 0 0 20px; color:#fff;  }	
	header.banner .nav-primary li.mega .sub-menu .sub-menu li { list-style-type:disc }	
	header.banner .nav-primary li.mega .sub-menu .sub-menu a { font-weight:400 }	
	.nav-primary li.mega > .sub-menu { position: absolute; width: 660px!important; left: 0; }	
	.nav-primary li.mega div.mega-col-left, .nav-primary li.mega div.mega-col-center, .nav-primary li.mega div.mega-col-right {
		float: left; padding: 0; width: 36%;
	}	
	.nav-primary li.mega div.mega-col-center {width: 34%; }
	.nav-primary li.mega div.mega-col-right { width: 26%;} 	
	header.banner .nav-primary li .sub-menu li .sub-menu { display:block; left: 0;	position: relative; }
	header.banner .nav-primary li .sub-menu a { padding: 0.3rem 0; 	}	
	header.banner .nav-primary li ul.sub-menu ul.sub-menu, header.banner .nav-primary li ul.sub-menu ul.sub-menu li { padding: 0 }
	header.banner .nav-primary li ul.sub-menu li.feature {     
		background: url('/wp-content/uploads/2022/06/feature-mega-menu.jpg') no-repeat;min-height: 150px; background-position: 0 8px;
	}
	header.banner .nav-primary li ul.sub-menu li.feature a { opacity:0; min-height:150px;  }  /* imporve this */
}	
@media screen and (min-width: 1200px) { .nav-primary li.mega > .sub-menu { position: absolute; width: 750px!important; left: 0; } }

@media screen and (max-width:992px) { 	
	header.banner a.rfq { display: none;}
	header.banner .nav-primary li.mobile { display: block;}
}
@media screen and (max-width:480px) { 
	header.banner #menu-header-menu li a { font-size: .75rem; font-weight: 500; text-transform: none; padding-right: .5em; } 
	header.banner #menu-header-menu li { padding: 0 0.5em 0 0; margin: 0;}
}	


/*  media queries and responsive  */
.navbar-toggle { display: none; cursor: default;}

@media (max-width: 992px) {
	header.banner {
		.navbar-toggle {
			display: block;
			padding: 0 8px 8px 8px;
			float: right;
			background: transparent;
			border: 0;
			.icon-bar { background: $brand-primary; }
		}
		.navbar-toggle.offcanvas-toggle.js-offcanvas-has-events {
			position: absolute;
			right: 0;
			top: 20px;
		}
		.navbar-offcanvas.in {
			left: -250px !important;
		}
	}
}

@media (max-width: 768px) {
	.nav-primary .navbar-offcanvas.in {
		left: 300px !important;
	}
}

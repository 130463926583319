.blog {
	article {
		padding-bottom: 3rem;
		.entry-meta {
			padding-bottom: 1.5rem;
		}
	}
}

.single {
	article {
		    padding-top: 3rem;
	}
	.entry-meta {
		padding-bottom: 1.5rem;
	}

}

section.widget {
	padding: 1rem 0;
}

.blog-sidebar {
    padding-top: 3rem;
}


/*.single-post article.post {
	padding-top: 3.5rem;
}
.single-post .entry-content img.wp-post-image {
	float: right;
	padding: 0 0 1em 1em;
}
.blog h2.entry-title a { 
	color:$body;
}

.entry-content, .blog-sidebar {
	margin-top: 5rem;
	transition: margin-top 0.1s;
}
@media (min-width: 768px) {
	.entry-content { padding-right:30px; }
	.blog-sidebar { padding-left: 30px; border-left: 1px solid rgba(0,0,0,.1);}
}
@media (max-width: 768px) {.entry-content, .blog-sidebar { margin-top: 4rem; } }

h1.entry-title { color:#000; font-size:48px;line-height:1 }
h2.entry-title { color:#000; font-size:40px;line-height:1; text-transform:uppercase; }
h2.entry-title a { color:#000; }
h2:hover.entry-title a { color: #f7a501; }
.entry-summary a { color:#333; text-decoration:underline; }

section.widget ul { list-style-type:none;padding:0 }
section.widget ul li {  margin-bottom: .5em; }
section.widget a { color:#fff; text-transform:uppercase;}
section.widget a:hover { color:#f7a501; }
section.widget h3 { color:#333; }

*/
